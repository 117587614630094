import type { ComponentProps } from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/lib/cn";

const headingVariants = cva("font-bold", {
  variants: {
    size: {
      h1: "text-4xl",
      h2: "text-3xl",
      h3: "text-2xl",
      h4: "text-1xl",
      h5: "text-lg",
    },
  },
  defaultVariants: {
    size: "h1",
  },
});

interface Props
  extends ComponentProps<"h1">,
    VariantProps<typeof headingVariants> {
  children: React.ReactNode;
}

export const Heading = ({ size, className, children, ...props }: Props) => (
  <h1 className={cn(headingVariants({ size }), className)} {...props}>
    {children}
  </h1>
);
