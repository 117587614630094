import { useState } from "react";
import { useForm, type SubmitHandler } from "react-hook-form";
import { typeboxResolver } from "@hookform/resolvers/typebox";
import { Object, String, type Static } from "@sinclair/typebox";
import LucideSend from "~icons/lucide/send";
import LucideLoaderCircle from "~icons/lucide/loader-circle";

import { TextField } from "@/components/TextField";
import { Button } from "@/components/Button";
import { Heading } from "@/components/Heading";

const loginFormSchema = Object({
  emailAddress: String({ minLength: 6 }), // shortest possible email is w@x.yz
});

type FormValues = Static<typeof loginFormSchema>;

export const LoginForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { register, handleSubmit } = useForm<FormValues>({
    resolver: typeboxResolver(loginFormSchema),
    progressive: true,
  });

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("emailAddress", data.emailAddress);

    const response = await fetch("/api/login", {
      redirect: "follow",
      body: formData,
      method: "POST",
    });

    if (!response.ok) {
      setIsLoading(false);
      return setError(await response.text());
    }

    if (response.redirected) {
      location.replace(response.url);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex w-[500px] max-w-screen-xl flex-col gap-4 rounded-xl p-16"
    >
      <Heading size="h2" className="justify-self-center">
        記者 KISHA
      </Heading>
      <Heading size="h3">Log in</Heading>
      <TextField
        type="email"
        id="emailAddress"
        className="w-full"
        placeholder="john.doe@g-global.com"
        message={error}
        intent={error ? "error" : undefined}
        {...register("emailAddress")}
      />
      <Button className="w-full" disabled={isLoading}>
        {isLoading ? (
          <LucideLoaderCircle className="animate-spin" />
        ) : (
          <>
            <LucideSend />
            Send magic link
          </>
        )}
      </Button>
    </form>
  );
};
